body {
    position: relative;
}

a {
    &.button {
        &--log-out {
            position: absolute;
            z-index: $z-index__header - 3;
            right: 0; bottom: 0;
            border-radius: $border__radius 0 0 0;

            html.headroom--above-the-fold & {
                position: fixed;
                transition: opacity .2s linear;
            }

            html.headroom--above-the-fold.headroom--near-the-fold & {
                opacity: 0;
            }

            html.headroom--above-the-fold.headroom--near-the-fold.headroom--scrolling-up & {
                transition: none;
            }
        }
    }
}
